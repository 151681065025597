import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { ActivatedRoutesService, OccEndpointsService, UserIdService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { CART_GROUP } from 'src/app/shared/constants/constants';
import { LoadItems, RetrievCartList } from 'src/app/store/Actions/cartItem.action';
import { getCartCode, getCartItems } from 'src/app/store/Selectors/cartItem.selector';
import { EntryType, VisionCareCartItems, VisionCareGroupEntries } from 'src/app/store/States/cartItem.state';
import { PatientListService } from '../patients-list/patient-list.service';

@Injectable({
  providedIn: 'root',
})
export class CartItemsService {
  isExceptionForSimulation = false;
  entryInfo = [];
  poNumber: string;
  cartErrorData = [];
  constructor(
    private http: HttpClient,
    private userIdService: UserIdService,
    private occEndPoints: OccEndpointsService,
    private store: Store,
    private activatedRoutesService: ActivatedRoutesService,
    private patientListService: PatientListService
  ) {}
  cartUpdated: Subject<any> = new Subject<any>();
  loading$: Subject<boolean> = new Subject<boolean>();
  private poNumberCheckSubject = new BehaviorSubject(false);
  private removeCartItem = new BehaviorSubject(false);
  poNumberValidation = this.poNumberCheckSubject.asObservable();
  removeCartItemStatis = this.removeCartItem.asObservable();
  private reloadCartSubject: Subject<any> = new Subject<any>();
  reloadCart = this.reloadCartSubject.asObservable();
  cartError: Subject<any> = new Subject<any>();
  private loadCreditCartSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  getCreditCardData = this.loadCreditCartSubject.asObservable();

  ValidatePonumber(isError): any {
    this.poNumberCheckSubject.next(isError);
  }

  /** deprecarted */
  getCartItems(): Observable<any> {
    return combineLatest([this.userIdService.getUserId(), this.store.select(getCartCode)]).pipe(
      take(1),
      switchMap(([userId, cartId]) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/vccarts/${cartId}`);
        return this.http.get<any>(url);
      })
    );
  }

  loadVcCart(): Observable<VisionCareCartItems> {
    return this.store.select(getCartItems).pipe(
      withLatestFrom(this.userIdService.getUserId()),
      switchMap(([cart, userId]) => {
        const { code } = cart;
        const url = this.occEndPoints.buildUrl(`users/${userId}/vccarts/${code}`);
        return code !== CART_GROUP.CODE ? of(cart) : this.loadCart(userId, code);
      }),
      take(1)
    );
  }
  addTocart(product): Observable<any> {
    return combineLatest([this.userIdService.getUserId(), this.store.select(getCartCode)]).pipe(
      take(1),
      switchMap(([userId, cartId]) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/carts/${cartId}/entries`);
        return this.http.post<any>(url, product.VCAddtocartItems);
      })
    );
  }
  addTocartForValuePack(product): Observable<any> {
    return combineLatest([this.userIdService.getUserId(), this.store.select(getCartCode)]).pipe(
      take(1),
      switchMap(([userId, cartId]) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/carts/${cartId}/valuePackEntries`);
        return this.http.post<any>(url, product.VCAddtocartItems);
      })
    );
  }
  updateCart(product): Observable<{ entries: VisionCareGroupEntries; positionId: string }> {
    return combineLatest([this.userIdService.getUserId(), this.store.select(getCartCode)]).pipe(
      take(1),
      switchMap(([userId, cartId]) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/carts/${cartId}/update`);
        return this.http.patch<any>(url, product.VCAddtocartItems);
      }),
      map((cartItem: VisionCareCartItems) => {
        // return only entries
        const rootGroup = cartItem.rootGroups[0];

        if (cartItem.rootGroups[0].label === EntryType.PRACTICE_ORDER) {
          return {
            entries: cartItem.rootGroups[0].children[0].entries[0],
            positionId: cartItem.positionId,
          };
        }

        if (cartItem.rootGroups[0].label === EntryType.DIRECT_TO_PATIENT) {
          return {
            entries: cartItem.rootGroups[0].entries[0].entries[0],
            positionId: cartItem.positionId,
          };
        }
      }),
      tap(() => this.store.dispatch(LoadItems()))
    );
  }

  updateCartEntryAddress(product): Observable<{ entries: VisionCareGroupEntries; positionId: string }> {
    return combineLatest([this.userIdService.getUserId(), this.store.select(getCartCode)]).pipe(
      take(1),
      switchMap(([userId, cartId]) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/carts/${cartId}/update`);
        return this.http.patch<any>(url, product.VCAddtocartItems);
      })
    );
  }

  simulateFinalPrice(): Observable<VisionCareCartItems> {
    return this.store.select(getCartItems).pipe(
      take(1),
      withLatestFrom(this.userIdService.getUserId()),
      switchMap(([cart, userId]) => {
        const { code } = cart;
        const url = this.occEndPoints.buildUrl(`users/${userId}/carts/${code}/orderSimulate`);
        if (cart.orderSimulation) {
          return code !== CART_GROUP.CODE ? of(cart) : this.loadCart(userId, code);
        } else {
          return this.http.post<VisionCareCartItems>(url, {}).pipe(
            tap((vCcartItems) => {
              this.isExceptionForSimulation = false;
              this.store.dispatch(RetrievCartList({ vCcartItems }));
            }),
            catchError(() => {
              this.isExceptionForSimulation = true;
              return code !== CART_GROUP.CODE ? of(cart) : this.loadCart(userId, code);
            })
          );
        }
      })
    );
  }

  loadCart(userId, code): Observable<VisionCareCartItems> {
    const url = this.occEndPoints.buildUrl(`users/${userId}/vccarts/${code}`);
    return this.http
      .get<VisionCareCartItems>(url)
      .pipe(tap((vCcartItems) => this.store.dispatch(RetrievCartList({ vCcartItems }))));
  }

  removeFromCart(entries): Observable<any> {
    return combineLatest([this.userIdService.getUserId(), this.store.select(getCartCode)]).pipe(
      take(1),
      switchMap(([userId, code]) => {
        const cartdata = {
          code,
          entries,
        };

        const url = this.occEndPoints.buildUrl(`users/${userId}/carts/${code}/remove/entries`);
        return this.http.post<any>(url, cartdata);
      })
    );
  }
  placeOrder(): Observable<any> {
    return combineLatest([this.userIdService.getUserId(), this.store.select(getCartCode)]).pipe(
      take(1),
      switchMap(([userId, cartId]) => {
        let url = '';
        if (this.poNumber) {
          url = this.occEndPoints.buildUrl(`users/${userId}/orders?cartId=${cartId}&poNumber=${this.poNumber}`);
        } else {
          url = this.occEndPoints.buildUrl(`users/${userId}/orders?cartId=${cartId}`);
        }
        return this.http
          .post<any>(url, {})
          .pipe(tap(() => this.patientListService.getPatientsList().pipe(take(1)).subscribe()));
      })
    );
  }
  setPOnumber(data): any {
    this.poNumber = data;
  }
  getPOnumber(): any {
    return this.poNumber;
  }
  setRemoveCartItem(): void {
    this.removeCartItem.next(true);
  }
  ScheduledOrder(scheduledProduct): Observable<any> {
    return combineLatest([this.userIdService.getUserId()]).pipe(
      take(1),
      switchMap(([userId]) => {
        let url;
        scheduledProduct?.code
          ? (url = this.occEndPoints.buildUrl(`users/${userId}/replenishmentOrders/${scheduledProduct.code}`))
          : (url = this.occEndPoints.buildUrl(`users/${userId}/replenishmentOrders`));

        return this.http.post<any>(url, scheduledProduct.VCAddtocartItems);
      })
    );
  }

  /**
   * Can be used to fetch route data outside router oultet component
   * Returns currently activated route data
   */
  getActivatedRouteData(): Observable<any> {
    return this.activatedRoutesService.routes$.pipe(
      map((routesArray: ActivatedRouteSnapshot[]) => {
        const currentActiveRoute: ActivatedRouteSnapshot = [...routesArray].pop();
        return currentActiveRoute.data;
      })
    );
  }
  setCartErrorData(data, isError): any {
    isError ? this.cartErrorData.push(data) : (this.cartErrorData = this.cartErrorData.filter((item) => item !== data));
    this.cartError.next(this.cartErrorData.length > 0);
  }
  clearCartErrorData(): any {
    this.cartErrorData = [];
  }
  reloadCartData(cartData): void {
    this.reloadCartSubject.next(cartData);
  }
  setLoading(loading): void {
    this.loading$.next(loading);
  }

  setCreditCardData(data): void {
    this.loadCreditCartSubject.next(data);
  }
}
