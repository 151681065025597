import { TranslationResources } from '@spartacus/core';
import { enAU } from './en_AU/index';
import { enGB } from './en_GB/index';
import { ptBR } from './pt_BR/index';
import { deDE } from './de_DE/index';
import { esES } from './es_ES/index';
import { itIT } from './it_IT/index';
import { frFR } from './fr_FR/index';
import { enIN } from './en_IN/index';
import { enIE } from './en_IE/index';

export const alconTranslations: TranslationResources = {
  en_GB: enGB,
  en_AU: enAU,
  en_NZ: enAU, // since NZ and AU have same translations
  pt_BR: ptBR,
  de_DE: deDE,
  es_ES: esES,
  it_IT: itIT,
  fr_FR: frFR,
  en_IN: enIN,
  en_IE: enIE
};
