export const enIE = {
    contactlenses: {
      contactlenses: 'Contact Lens',
    },
    dailycontactlenses: {
      dailycontactlenses: 'Daily contact lenses',
    },
    fortnightlycontactlenses: {
      fortnightlycontactlenses: 'Fortnightly contact lenses',
    },
    monthlycontactlenses: {
      monthlycontactlenses: 'Monthly contact lenses',
    },
    dryeyeproducts: {
      dryeyeproducts: 'Dry eye products',
    },
    contactlenssolutions: {
      contactlenssolutions: 'Contact lens solutions',
    },
    contactlenssolution: {
      contactlenssolution: 'Contact lens solutions',
    },
    multifocal: {
      multifocal: 'Multifocal',
    },
    colour: {
      colour: 'Colour',
    },
    color: {
      color: 'Color',
    },
    spherical: {
      spherical: 'Spherical',
    },
    toric: {
      toric: 'Toric',
    },
    directtopatient: {
      directtopatient: 'Direct to patient',
    },
    valuepack: {
      valuepack: 'Value pack',
    },
    practiceorder: {
      practiceorder: 'Practice order',
    },
    practice: {
      practice: 'Practice',
    },
    stockorder: {
      stockorder: 'Stock Order',
    },
    regular: {
      regular: 'Regular',
    },
    individualorders: {
      individualorders: 'Individual Orders',
    },
    valuepacks: {
      valuepacks: 'Value packs',
    },
    stockorders: {
      stockorders: 'Stock orders',
    },
    directtopatients: {
      directtopatients: 'Direct to patients',
    },
    directpurchase: {
      directpurchase: 'Direct purchase',
    },
    packsize: {
      packsize: 'Pack size',
    },
    diameter: {
      diameter: 'Diameter',
    },
    lenscylinder: {
      lenscylinder: 'Lens Cylinder',
    },
    lensaxis: {
      lensaxis: 'Lens axis',
    },
  
    common: {
      formErrors: {
        required: 'This field is required',
      },
      common: {
        home: 'Home',
      },
      skipLink: {
        skipTo: 'Skip to',
        labels: {
          header: 'Header',
          main: 'Main Content',
          footer: 'Footer',
          productFacets: 'Product Facets',
          productList: 'Product List',
        },
      },
      pageMetaResolver: {
        category: {
          title: '{{count}} result for {{query}}',
          title_plural: '{{count}} results for {{query}}',
        },
        checkout: {
          title: 'Checkout',
        },
        search: {
          title: '{{count}} result for "{{query}}"',
          title_plural: '{{count}} results for "{{query}}"',
          findProductTitle: '{{count}} result for coupon "{{coupon}}"',
          findProductTitle_plural: '{{count}} results for coupon "{{coupon}}"',
          default_title: 'All products',
        },
        product: {
          description: '{{description}}',
          heading: '{{heading}}',
          title: '{{title}}',
          packSizeWarningMessage: 'Please check if your pack size is correct',
        },
      },
    },
  
    cms: {
      errorHandler: {
        message: 'Something went wrong. Go back and try again or contact customer service.',
        unauthorized_01:
          'No Proper Profile setup found (user is inactive, no billing address is present or profile is setup incorrectly). Contact customer service or your account administrator.',
        unauthorized_02:
          'You do not have any shipping addresses assigned. Contact your account administrator or customer service.',
      },
      login: {
        backToLogin: 'Back to login',
        backToLoginPage: 'Back to login page',
        emailAddress: 'E-mail address',
        emailPlaceholer: 'Your e-mail adress',
        emailSentConfirmText: 'An e-mail has been sent with the instructions to reset your password.',
        forgotLink: 'Forgot password?',
        forgotPwdText: 'Enter your e-mail address and we will send you a link to change your password:',
        logIn: 'log in',
        passwordLbl: 'Password',
        requireEmail: 'Please enter a valid e-mail',
        requirePassword: 'Please enter your password',
        sendEmail: 'Send',
        forgotYourPwd: 'Forgot your password?',
        applyNow: 'Apply Now',
      },
      multiAccountSelector: {
        message: 'Choose an account to continue. Products availability and shopping experience may vary.',
        title: 'Select an Alcon account',
        loaderMessage: ' Loading Accounts ...',
        welcomeMessage: 'Welcome ',
        verifyAccessError: `<b>We couldn’t verify access to this account.</b><br>Please check the account number and try again.<br>Or contact customer service with any question.`,
        requestAdditionalAccount: 'Request access to an additional Alcon account',
        requestLabel: 'Enter Alcon account number',
        requestSend: 'Send Request',
        requestSending: 'Sending request',
        alreadyExistAccount: 'You already have access to this account.',
        loading: 'Loading...',
        shipToSelector: {
          title: 'Your Alcon account:',
          searchboxHeading: 'Select a shipping address',
          searchPlaceholderTxt: 'Enter shipping address',
          noResultFound: 'No results found',
        },
        soldToselector: {
          requestAdditionalAccount: 'Request access to an additional account',
          canNotFoundAccount: 'Can’t find your account?',
        },
      },
      productListingPage: {
        moreFilter: 'More filters',
        lessFilter: 'Less filters',
        product: 'product',
        seeAll: 'See All',
        select: 'Select',
        showMore: 'Show more',
        showLess: 'Show less',
        sortBy: 'Sort by',
        results: 'Results',
        products: 'products',
        nameAZ: 'Name AZ',
        price: 'Price',
        oxygenPermeability: 'Oxygen permeability',
        brand: 'Brand',
        categories: 'Categories',
        showAll: 'Show all',
        configureProducts: 'Configure products',
        valuepack: 'Value packs',
        details: 'Details',
      },
      productPage: {
        productDescription: 'Product description',
        productSpecification: 'Product specifications',
        features: 'Features',
        benefits: 'Benefits',
        downloads: 'Downloads :',
      },
      stockOrderProductListPage: {
        from: 'from',
      },
      productDetailsPage: {
        privateLabel: 'Private label',
        patientReference: 'Patient reference:',
        parametersFor: 'Parameters for:',
        left: 'Left',
        right: 'Right',
        baseCurve: 'Base curve:*',
        power: 'Power:*',
        add: 'Add*:',
        colour: 'Colour*:',
        cylinder: 'Cylinder*:',
        axis: 'Axis*:',
        sameForBothEye: 'Same for both eye',
        packSize: 'Pack size:',
        individualOrders: 'Individual orders',
        dtpError: 'This product can only be delivered to practice.',
        dtpSummaryError: 'This order can only be delivered to practice.',
        addressError: 'Please provide correct address',
        errorMessage: 'You cannot enter more than 18 characters.',
        quantityErrorMsg: 'Max. purchasable quantity for this item is',
        PurchaseStockOrder: 'Purchase in stock order',
        perEye: 'per eye',
        each: 'each',
        finalPrice: '* final price will be calculated during checkout process',
        practiceOrder: 'Practice order',
        directToPatient: 'Direct to patient',
      },
      practiceOrderPage: {
        add: 'Add:*',
        axis: 'Axis:*',
        baseCurve: 'Base curve:',
        cancel: 'Cancel',
        colour: 'Colour:*',
        cylinder: 'Cylinder:*',
        diameter: 'Diameter:',
        dtpLimitMessage: 'Max. quantity for Direct to Patient delivery is',
        errorMessage: 'Too many items selected. Please lower quantity to max.',
        individualOrders: 'Individual orders',
        left: 'Left eye',
        LEFT: 'Left eye',
        material: 'Material:',
        packSize: 'Pack size:*',
        parametersFor: 'Parameters for:',
        patientReference: 'Patient reference:',
        power: 'Power:*',
        practiceOrder: 'Practice order',
        prefer: 'Prefer a value pack?',
        quantity: 'Quantity:',
        right: 'Right eye',
        RIGHT: 'Right eye',
        sameForBothEye: 'Same for both eyes',
        schedule: 'Schedule recurring order',
        typeOfWear: 'Type of wear:',
        update: 'UPDATE',
        waterContent: 'Water content:',
        productColor: 'COLOR',
        productMultifocal: 'MULTIFOCAL',
        productSpherical: 'SPHERICAL',
        productToric: 'TORIC',
        stockOrder: 'STOCK_ORDER',
        stockOrderPAge: 'stockOrder',
        practiceOrderPage: 'practiceOrder',
        regularInCap: 'REGULAR',
        regularInSmall: 'regular',
        valuePacks: 'VALUE_PACK',
        valuePack: 'Value pack',
        directToPatient: 'Direct to patient',
        dtpValuePacks: 'DTP_VALUE_PACK',
        practice: 'PRACTICE',
        directToPatientBlockText: 'DIRECT_TO_PATIENT',
      },
      favoriteListPage: {
        yourFavProduct: 'Your favourite products',
        products: 'products',
        product: 'product',
        noFavProduct:
          'You currently do not have any favourite products listed. To add products to the favourites list click on the heart icon next to the product',
      },
      legalAnnouncement: {
        accept: 'ACCEPT',
        decline: 'DECLINE',
      },
      practicePage: {
        mostFrequentOrders: 'Most frequent orders',
      },
      stockOrderPage: {
        configureProducts: 'Configure product',
        packSize: 'Pack size:*',
        baseCurve: 'Base curve:',
        diameter: 'Diameter:',
        color: 'Colour:',
        add: 'Add:',
        cancel: 'Cancel',
        lenscylinder: 'Lens Cylinder:*',
        moreMinus: 'More minus (-) powers',
        lessMinus: 'Less minus (-) powers',
        morePlus: 'More plus (+) powers',
        lessPlus: 'Less plus (+) powers',
        clearAll: 'Clear all',
        addToCart: 'ADD TO CART',
        addToCartLoading: 'ADDING TO CART',
        addToCartSuccess: 'ADDED TO CART',
        updateToCartText: 'UPDATE',
        updatingCart: 'Updating Cart',
        updateToCartSuccess: 'Updated Cart',
        maxQty: 'Max. total quantity for this product is',
        errorMessage: 'Too many items selected. Please lower quantity to max.',
        inputMessageError: 'Quantity must be number greater than 0',
        each: 'each',
        forthisbrand: 'for this brand',
      },
      miniCartPage: {
        PRACTICE_ORDER: 'Practice orders',
        INDIVIDUAL_ORDER: 'Individual orders',
        VALUE_PACK: 'Value pack',
        DTP_VALUE_PACK: 'Value pack',
        DIRECT_TO_PATIENT: 'Direct to patient',
        STOCK_ORDER: 'Stock order',
        title: 'Your cart',
        RIGHT: 'Right',
        LEFT: 'Left',
        total: 'Total',
        delete: 'Delete',
        gotocart: 'Go to Cart',
        moreitem: 'more item',
        totalprice: '£00.00',
        yourcartIsEmpty: 'Your cart is empty',
        goTo: 'Go to',
        productCatalogue: 'Product Catalogue',
        orClick: 'or click',
        orClickthe: 'or click the',
        newOrder: 'New order',
        editOrder: 'Edit order',
        toAddItems: 'to add items',
        edit: 'Edit',
        patientReference: 'Patient reference',
        quantity: 'Qty.',
        YouHaveNoProductsInYourCart: 'You have no products in your cart',
        seeProduct: 'See products',
        hideProduct: 'Hide products',
        deliveryMethod: 'Delivery method:',
        poNumber: 'PO number:',
        orderNumber: 'Order number:',
        LP: 'PWR',
        AX: 'AX',
        CY: 'CYL',
        Colour: 'Colour',
        DIA: 'DIA',
        BC: 'BC',
        ADD: 'ADD',
        PatientReference: 'Patient Reference',
        PowerShipped: 'powers shipped',
        PowersInProgress: 'powers in progress',
        cartMessage1: 'You do not have any products added to your cart.',
        cartMessage2: 'Use any of the following methods to add products to your order. ',
        cartMessage:
          'You do not have any products added to your cart. Use any of the following methods to add products to your order. ',
        valuePack: 'Value pack:',
        valuePacks: 'Value packs',
        valuePackDuration: 'Value pack (',
        valuePackMonth: '-months)',
        moreitems: 'more items',
      },
      cartPagev2: {
        INDIVIDUAL_ORDER: 'Individual orders',
        VALUE_PACK: 'Value packs',
        STOCK_ORDER: 'Stock orders',
        DIRECT_TO_PATIENT: 'Direct to patients',
        orderAndPay: 'ORDER AND PAY',
        simulation: {
          errorMessage:
            'The final price can not be calculated at this time. Once this issue has been resolved you will see the prices in the order history and invoices.',
        },
        directToPatientWarning: 'Final price of each Direct to patient order will be available in your invoice',
        warningOnCheckout:
          '*Please note that the prices on MyAlcon Store are subject to change and could change during the ordering process. The exact price for the product will be shown on your invoice.',
        practiceWarning: 'Final price of Practice order will be available in your invoice',
        disclaimer:
          'By clicking ORDER AND PAY  you acknowledge and agree to 1) the MyAlcon terms and conditions which you signed up to when you registered your details to use this system and 2) confirm that where it is a direct-to-patient order a) the patient is 16 years or over and b) you have informed the patient that their information will be shared via MyAlcon and have obtained any necessary patient consents.',
        deliveryCost: 'Delivery costs',
        taxes: 'Taxes',
        estimatedTotalPrice: 'EST. ORDER TOTAL',
        total: 'Total',
      },
      stockToricPage: {
        lensPower: 'Lens power',
        lensAxis: 'Lens axis',
        message: 'Select power to see fitted lens axis',
      },
      cartPage: {
        PRACTICE_ORDER: 'Practice orders',
        summary: 'Summary',
        goToCheckout: 'GO TO CHECKOUT',
        ShippingAddress: 'Shipping address',
        DeliveryMethod: 'Delivery method',
        calcFinalPrice: 'CALCULATE FINAL PRICE',
        DIRECT_TO_PATIENT: 'Direct to patient',
        deliveryMethod: 'Delivery Method:',
        deliveryMethodDetails: 'Standard delivery (2-3 working days)',
        IndividualOrders: 'Individual orders',
        StockOrders: 'Stock orders',
        ValuePacks: 'Value packs',
        DirecttoPatient: 'Direct to patients',
        products: 'Products',
        shippingsuggestion: 'Shipping will be calculated at checkout.',
        patients: 'patients',
        externalOrderMsg: 'Final price will be revealed on the invoice',
        cancelOrder: 'CANCEL ORDER',
      },
      orderConfirmation: {
        orderNumber: 'Order number:',
        shippingAddress: 'Shipping address:',
        deliveryMethod: 'Delivery Method:',
        deliveryMethodDetails: 'Standard delivery via post',
        poNumber: 'PO number:',
        valuePackQuantity: '1 item',
        item: 'items',
      },
      checkoutPage: {
        paymentMethod: 'Payment method',
        paymentType1: 'Credit card',
        paymentType2: 'Invoice to account',
        valuePackQuantity: '1 item',
        poNumber: 'PO number:',
        LP: 'Power',
        AX: 'Axis',
        CY: 'Cylinder',
        Colour: 'Colour',
        DIA: 'DIA',
        BC: 'BC',
        ADD: 'ADD',
        print: 'Print',
        finalPrice: {
          products: 'Products',
          delivery: 'Delivery',
          taxes: 'Taxes',
          total: 'TOTAL',
          placeOrder: 'Place Order',
          obligationText: 'with obligation to pay',
          estimatedDelivery: 'Estimated delivery time:',
          loaderMessage: 'Please wait a moment, we are collecting the data',
        },
        delivery: 'Delivery:',
        practiceOrders: 'Practice orders ',
        orderTypes: {
          individual: 'Individual orders',
          valuePack: 'value packs',
          stock: 'stock orders',
        },
        practiceOrdersPanel: 'Individual orders, value packs, stock orders',
        directtoPatients: 'Direct to patients ',
        seeAll: 'See all',
        status: 'Status',
        patients: 'patients',
        addNewCardHeading: 'Add a new card',
        setDefaultCard: 'Set this card as default',
        selectPaymentCard: 'Select payment card',
        selectCardtitle: 'Select a payment card from your saved cards or add a new card.',
      },
      myPatientsPage: {
        addItems: '+ ADD PATIENT',
        addNewPatient: {
          heading: 'Add new patient',
          editHeading: 'Edit patient information',
          personalData: 'Personal data',
          mainAddress: 'Main address',
          additionalAddress: 'Additional address',
          form: {
            title: 'Title',
            firstName: 'First name *',
            surName: 'Surname *',
            email: 'E-mail address',
            phoneNumber: 'Phone number',
            referenceNumber: 'Reference number',
            address: {
              street: 'Street and number *',
              building: 'Building / apartment / other',
              postcode: 'Postcode *',
              city: 'City *',
              county: 'County',
              notMandatoryCounty: 'County',
              country: 'Country *',
            },
            mandatoryFields: '* Mandatory fields',
            additionalAddress: 'Add additional address',
            deleteadditionaladdress: 'Delete additional address',
            clearAll: 'Clear all',
            save: 'save',
            cancel: 'Cancel',
            update: 'Update',
            errorMessages: {
              title: 'Please select title.',
              mandatoryField: 'This field is mandatory.',
              firstName: `Please fill in the patient's first name.`,
              surName: `Please fill in the patient's last name.`,
              email: 'E-mail is in the incorrect format. Please use the following format: john@gmail.com',
              phoneNumber: 'You cannot use letters in this field.',
              phoneNumberMinLength: 'Phone number is too short. Please verify if you entered all characters.',
              moreThanThirtyCharacters: 'You cannot enter more than 30 characters in this field.',
              street: `Please fill in the patient's street address.`,
              buildingNumberName: `Please fill in the patient's building number.`,
              postcode: `Please fill in the patient's postcode.`,
              city: `Please fill in the patient's city.`,
              county: `Please fill in the patient's county.`,
              country: `Please fill in the patient's country.`,
              invalidPattren: 'Invalid postcode',
              selectCountry: 'Please select a country',
            },
          },
          patientAddedSuccessfully: 'Patient has been successfully added',
        },
        address: 'Address',
        showMore: 'Show More',
        showLess: 'Show Less',
        mostRecentOrders: 'Most recent orders',
        order: 'Order',
        orderNo: 'Order no. ',
        subscription: 'Subscription',
        delivery: 'Delivery',
        shippingAddress: 'Shipping address',
        status: 'Status',
        packtype: 'Pack type',
        date: 'Date',
        frequency: 'Frequency',
        addOrder: 'Add order',
        addOrderButton: '+ ADD ORDER',
        options: 'Options',
        active: 'active',
        inactive: 'inactive',
        switchtoSubscription: 'Switch to subscription',
        sort: 'Sort:',
        orderHistory: 'Order history',
        editPatient: 'Edit patient',
        deletePatient: 'Delete patient',
        search: 'Search patient name, reference no.',
        noPatientFound: 'You have no patients yet. Add your first patient by clicking the button below.',
        searchNoPatient: 'No patient found. Check if you typed everything correctly.',
        noPatientsFound: 'No patients found',
        adjustFilters: 'Try adjusting the applied filters or ',
        addessRemovedMsg:
          'Delivery address for this subscription was changed to the main one, as additional address is no longer available',
        ok: 'Ok',
        top: 'Top',
        fetchingOrders: 'Fetching Orders ...',
        patientName: 'Patient Name',
        warningMessage: 'Issue occurred while retrieving order details. Contact customer service.',
      },
      patientListFilters: {
        filterPlus: '+  Filters',
        filterMinus: '-  Filters',
        ordertype: 'Order type:',
        status: 'Status:',
        packType: 'Pack type:',
        delivery: 'Delivery:',
        clearfilters: 'Clear filters',
        clearallfilters: 'clear all filters',
      },
      patientProfilePage: {
        personalData: 'Personal data',
        mainAddress: 'Main address',
        additionalAddress: 'Additional address',
        edit: 'Edit',
        tel: 'Tel:',
        eMail: 'E-mail:',
        refNumber: 'Reference no.:',
        addOrder: '+ ADD ORDER',
        noOrder: 'There are no orders yet',
      },
      orderHistoryPage: {
        orderPlaced: 'Order placed',
        orderDate: 'Order date',
        orderNo: 'Order no.',
        type: 'Type',
        orderSource: 'Order source',
        shippingLocation: 'Shipping location',
        totalPrice: 'Total price',
        status: 'Status',
        invoice: 'Invoice',
        search: 'Search order number or PO number',
        searchButton: 'search',
        timePeriod: 'Time period:',
        dateRange: 'Date range:',
        selectDate: 'Select date(s)',
        sort: 'Sort:',
        reorder: 'REORDER',
        viewOrderdetails: 'View order details',
        noOrders: 'You have not ordered any products yet.',
        noOrderText: 'When you submit an order, you will be able to view the status and details of your order here.',
        filter: {
          ALL: 'All',
          ACTIVE: 'Active',
          COMPLETED: 'Completed',
          STATUS: 'Status',
          filterPlus: '+  Filters',
          filterMinus: '-  Filters',
          orderStatus: {
            NEW: 'New',
            IN_PROGRESS: 'In progress',
            SHIPPED: 'Shipped',
            CANCELLED: 'Cancelled',
            BILLED: 'Billed',
            PARTIAL_BACKORDER: 'Delayed',
            BACKORDER: 'Backorder',
          },
        },
        showMore: 'SHOW 10 MORE OUT OF',
        noResults: 'No orders found. Check if you typed correct number or try with another one.',
        noResultsFilter: 'No orders found for the specified filter criteria',
        cancel: 'CANCEL',
        Order: 'Found 1 order',
        Orders: 'Found {{count}} orders',
        poNumber: 'PO number',
        cancelOrderConfirmation: 'Are you sure you want to cancel this order?',
        cancelOrderInfoMsg: 'This action cannot be reversed.',
        cancelOrder: 'Cancel order',
        keepOrder: 'Keep order',
        orderCancelSuccessfull: 'Order was successfully cancelled',
      },
      invoicesListPage: {
        invoiceNo: 'Invoice no.',
        dueDate: 'Due Date',
        orderNo: 'Order no.',
        totalPrice: 'Total Price',
        dateRange: 'Date range',
        sort: 'Sort:',
        newest: 'From newest',
        oldest: 'From oldest',
        invoicesHeading: 'Invoices',
        orders: 'orders',
        orderNumber: 'Order No.',
  
        filter: {
          ALL: 'All',
          PAID: 'Paid',
          UNPAID: 'Unpaid',
          OVERDUE: 'Overdue',
          filterPlus: '+  Filters',
          filterMinus: '-  Filters',
        },
        search: {
          DAILYINVOICE: 'Search invoice number or order number',
          INVOICELIST: 'Search invoice number',
          searchButton: 'Search',
          noResults: 'No invoices found. Check if you typed correct number or try with another one.',
          invoice: 'Found 1 invoice',
          invoices: 'Found {{count}} invoices',
        },
      },
      baseLoginPage: {
        youWereLoggedOut: 'You were logged-out',
        logoutMsg:
          'Your session has been timed out. For the safety of you and your patients, you have been automatically logged out.',
        welcome: 'Welcome',
        backToLogin: 'Back to login page',
      },
      orderStatus: {
        NEW: 'New',
        IN_PROGRESS: 'In progress',
        SHIPPED: 'Shipped',
        CANCELLED: 'Cancelled',
        PENDING_CONSOLIDATION: 'Waiting for order consolidation',
        BILLED: 'Billed',
        PARTIAL_BACKORDER: 'Delayed',
        BACKORDER: 'Backorder',
      },
      confirmEmail: {
        success: 'E-mail address confirmed. Our sales representative will contact you shortly.',
        failure: 'Link expired. Please fill out registration form again.',
      },
      registerPage: {
        registrationFormTitle:
          'Open an account to gain 24/7 access to online ordering, invoices, statements, order tracking, and other account management features.',
        imAlconCustomer: 'I am an Alcon customer',
        iDoNotHaveAnAccountTitle: 'I do not have Alcon account yet',
        iDoNotHaveAnAccountDescription:
          'That is no problem at all! You can leave your contact details and Alcon sales representative will contact you shortly!',
        termsAndConditions: 'Terms and conditions',
        consent: 'Consent',
        termsAndConditionTitle: 'Registration to Alcon e-commerce',
        termsAndConditionLabel: `I have read and agreed to the terms and conditions stated in <a src="#">Alcon Eye Care UK Limited General Conditions of Sale.</a>*`,
        termsAndConditionDisclaimer: `Alcon will always handle your personal data in accordance with all applicable laws. </br></br>Alcon is part of the Alcon Group, which is a global group of companies and has databases in different countries, some of which are operated by the local Alcon affiliate, and some of which are operated by third parties on behalf of the local Alcon affiliate. Alcon may transfer your data to one or more of its affiliates’ databases outside your country of domicile, including countries which may not require an adequate level of protection for your personal data compared with that provided in the UK or EU.</br></br>Alcon will not sell your personal data to any third parties for any purpose.</br></br>If you wish to contact us regarding our use of your personal data, change your preferences or object to the processing of your personal data, please email us at privacy.uk@alcon.com.`,
        setUpAnAccount: 'Set up an account',
        title: 'Title',
        firstName: 'First name*',
        lastName: 'Last name*',
        fullCompanyName: 'Full company name*',
        email: 'E-mail address for MyAlcon Store account*',
        accountNumber: 'Alcon account number*',
        next: 'Next',
        back: 'Back',
        cancel: 'Cancel',
        submit: 'Submit',
        mandatory: '* Mandatory fields',
        successText: `A copy of this form will be sent to your inbox.</br></br>Please note - it can take up to three working days to set up a new account from the day we receive the completed form including a valid GOC / GMC number.`,
        successTitle: 'Thank you for completing your application form to open an account with Alcon Eye Care UK Limited.',
        goToLoginPage: 'Go to login page',
        TypeOfProducts: 'Type of products*',
        selectProductType: 'Select product type',
        productTypeSurgical: 'IOLs and Surgical products',
        productTypeVisionCare: 'Contact lenses and Vision Care products',
        errorMessages: {
          mandatoryField: 'This field is mandatory.',
          maxLengthReached: 'You cannot use more than {{characterNumber}} characters in this field.',
          couldContainsOnlyNumbers: 'You cannot use letters in this field.',
          firstName: 'Please fill in First name',
          lastName: 'Please fill in Last name',
          fullCompanyName: 'Please fill in Full company name',
          email: 'Please fill in E-mail address',
          emailPattern: 'E-mail is in the incorrect format. Please use the following format: john@gmail.com',
          accountNumber: 'Please fill in Alcon account number',
          termsAndConditions: 'Please accept the mandatory fields',
          responseError: 'There were some issue with your request. Please try again or contact customer service',
          TypeOfProducts: 'Please select type of product',
        },
      },
      franchisePage: {
        surgicalProduct: 'Surgical Products (Custom Packs, IOL’s, etc.)',
        visionCareProduct: 'Vision Care Products (Contact Lenses, eye care products, etc.)',
        next: 'Next',
      },
      contactusPage: {
        pleaseSelectATopic: 'Please select a topic:*',
        typeYourMessageHere: 'Type your message here:*',
        contactMethod: 'Please select your preferred contact method for this request:*',
        contactMethodLabel1: 'E-mail',
        contactMethodLabel2: 'Phone',
        emailAddress: 'E-mail address',
        emailInfo:
          'Information entered above does not change the contact information associated with your account. You can change your phone number in My Profile or contact Customer Service to change your e-mail.',
        termsConditions: `Alcon will use this information to respond to your request. I have read Alcon's Privacy Policy and agree to its terms.*`,
        preferredTime: 'Please select your preferred time:*',
        preferredTimeLabel1: 'Morning',
        preferredTimeLabel2: 'Afternoon',
        contactName: 'Please provide a contact name:*',
        contactNameLabel: ' Contact name',
        fullCompanyName: 'Full company name',
        returnToLastVisitedPage: 'Return to last visited page',
        mandatoryFields: '*  Mandatory fields',
        provideContactName: 'Please provide a contact name:*',
        contactusSubmit: 'Submit',
        phoneNumber: 'Phone number',
        characters: '1000 characters',
        thankyoumsg: 'Thank you for your submission',
        additionalmsg: 'An Alcon representative will follow up with you regarding this matter within 1 business day.',
        returntoHome: 'Return to homepage',
        returnProdct: 'Return product',
        gotoform: 'Go to form',
        selectFile: 'Select file',
        errorMessages: {
          topic: 'Please select Topic.',
          mandatoryField: 'This field is mandatory.',
          message: `Please fill in the message.`,
          contactmethod: 'Please select at least one contact method',
          email: 'Please fill in the email',
          emailPattren: 'E-mail is in the incorrect format. Please use the following format: john@gmail.com',
          phoneNumber: `Please fill in the phone number.`,
          phoneNumberCharecters: 'You cannot use letters in this field.',
          phoneNumberMinLength: 'Phone number is too short. Please verify if you entered all characters.',
          preferredTime: 'Please select preferred contact time',
          contactName: 'Please fill in the contact name',
          termsConditions: 'Please select terms & conditions',
          contactNamePattern: 'You cannot enter more than 30 characters in this field',
        },
        salesRep: {
          title: 'My Sales Representative',
          phone: 'Phone:',
          email: 'Email:',
        },
        selectone: 'Select one',
      },
      selectProductPage: {
        selectPackType: '1. Select pack type',
        selectProducts: '2. Select products',
        regular: 'Regular',
        valuePacks: 'Value Pack',
        valuePack: 'Value pack',
        chooseLenses: 'Choose lenses:*',
        patientName: 'Patient: ',
        valuePacktooltip: 'Certain contact lenses and solutions are offered as a convenient bundle.',
        additionalProducts: 'Choose additional products:',
        contactLens: 'Contact lens solution',
        dryEye: 'Dry eye products',
        addAnotherproduct: 'Add another product',
        mandatory: '*  Mandatory fields',
        addOtherLenses: 'ADD OTHER LENSES',
        valuePackAttributes: {
          threeMonthValuePack: '3-month pack',
          sixMonthValuePack: '6-month pack',
          chooseSolution: 'Choose solution:*',
        },
        patientOrderPanel: {
          delivery: '3. Select delivery',
          practiceOrder: 'Practice Order',
          directToPatient: 'Direct to patient',
          selectOrderType: '4. Select order type',
          individual: 'Individual',
          total: 'Total:',
          addToCart: 'ADD TO CART',
          addToCartSuccess: 'ADDED TO CART',
          cancel: 'Cancel',
          address: 'Address:',
          delivertodiffAddress: 'Deliver to different address',
          selectdeliveryAddress: 'Select delivery address:',
          dropdown: {
            buttonText: 'Add patient',
          },
          scheduled: {
            orderType: 'Scheduled',
            repeatEveryText: 'Repeat every:',
            week: 'weeks',
            month: 'months',
            startDate: 'Start Date:',
            save: 'Save',
            startSubscriptions: 'START SUBSCRIPTIONS',
            addToCartNow: 'ADD TO CART NOW',
          },
          title: `Adding to cart failed`,
          description: `Items were not added to the cart. <br> Please resolve issues and try again.`,
          redirectButton: 'OK',
        },
        tooltipText: `You can’t select 2nd product if two eyes are selected. If you want to add another product for the same patient uncheck one eye.`,
      },
      siteDirectory: {
        errorMessage: {
          accountNotSetup: 'Your account is not setup for this country. Please choose correct site.',
        },
        heading: 'MyAlcon Store Global Directory',
        locationHeading: 'Choose a different location',
        discoverHeading: 'Discover Alcon eye care products',
        suggestedLocation: 'Suggested locations',
        chooseLocation: 'Choose your location',
      },
      safeOrderingPage: {
        safeOrdering: 'Safe ordering ',
      },
      configureProducts: {
        deliveryPanel: {
          delivery: 'Delivery',
          selectDelivery: 'Select delivery',
          practiceOrder: 'Practice Order',
          directToPatient: 'Direct to patient',
          patientReferencePlaceholder: 'Type reference',
          patientReference: 'Patient reference:',
          patientReferenceMandatory: 'Patient reference:*',
          needStockOrder: 'Need a stock order?',
          practiceErrorMessage: 'You cannot enter more than 30 characters.',
          noResultsFound: 'No results found',
          selectFromList: 'Select from the list',
          deliveryToDifferentAddress: 'Delivery to different address',
        },
      },
      orderDetailsPage: {
        orderNo: 'Order no.',
        shipped: 'Shipped',
        inProgess: 'In progress',
        delivered: 'Delivered',
        billOnly: 'Bill only',
        products: 'Products',
        ACCOUNT: 'Invoice to Account',
        CASH: 'Cash on Delivery',
        CARD: 'Credit Card',
        statusPanel: {
          partiallyShipped: 'Partially Shipped',
          trackingno: 'Tracking no.:',
        },
        paymentMethodPanel: {
          orderPlaced: 'Order placed:',
          orderDate: 'Order date:',
          orderSource: 'Order source:',
          paymentMethod: 'Payment Method:',
          cardEndingWith: 'Card ending with ****',
          cardEndsWith: 'Card ending with ',
          invoiceno: 'Invoice no.',
          addNewCard: 'Add new card',
          noCreditCard: 'You have no credit cards linked to this account.',
          Change: 'Change',
          useOneTime: 'USE CARD ONE TIME',
          useAndSaveLater: 'USE AND SAVE FOR LATER',
          useSelectedCard: 'USE SELECTED CARD',
          useAndSaveDefault: 'USE AND SAVE AS DEFAULT',
          useNewCard: 'USE NEW CARD',
          invoiceToAccount: 'Invoice to account',
        },
        deliveryMethodPanel: {
          practiceOrders: 'Practice orders',
          deliveryMethod: 'Delivery method:',
          shippingAddress: 'Shipping Address:',
          poNumber: 'PO number:',
          DTPOrders: 'Direct to patient',
        },
        finalPrice: {
          reOrder: 'reorder',
        },
        orderItemPage: {
          INDIVIDUAL_ORDER: 'Individual orders',
          STOCK_ORDER: 'Stock order',
          VALUE_PACK: 'Value pack',
          total: 'Total',
          item: '{{count}} item',
          item_plural: '{{count}} items',
          materialNumber: 'Item no.',
          serialNumber: 'Serial no.',
          IOL_Bill: 'IOL Bill',
          diopter: 'Diopter',
          estDeliveryDate: 'Estimated delivery date',
          deliveryDate: 'Delivery date',
          'labelstatus_In transit': 'In transit:',
          'labelstatus_In delivery': 'In delivery:',
          labelstatus_delivered: 'Delivered:',
          labelstatus_undefined: '',
          fromitem: '{{othercount}} from {{count}} items',
          tooltip: '{{othercount}} out of {{count}} items are {{status}}. The rest of the items have different statuses',
          exptDeliveryDate: 'Expected delivery date',
          customerReference: 'Customer reference',
          partialBODate_itemsShipped:
            '{{shippedQuantity}} items have been sent to you. The remaining {{backOrderQuantity}} items will be shipped after: {{backOrderDate}}',
          partialBODate_itemsNotShipped: `You will be sent {{unshippedQuantity}} items. The remaining {{backOrderQuantity}} items will be shipped after: {{backOrderDate}}`,
          partialBOWithoutDate_itemsShipped:
            '{{shippedQuantity}} items have been sent to you. The remaining {{backOrderQuantity}} items will be shipped to you as soon as possible.',
          partialBOWithoutDate_itemsNotShipped:
            'You will be sent {{unshippedQuantity}} items. The remaining {{backOrderQuantity}} items will be shipped to you as soon as possible.',
          fullBO_withDate: 'This product is temporarily out of stock. It will be sent after: {{backOrderDate}}',
          fullBO_withoutDate: `This product is temporarily out of stock. It will be shipped to you as soon as possible when it's available.`,
        },
      },
      selectDeliveryAddressPage: {
        mainaddress: 'Main address',
        additonaladdress: 'Additional address',
        edit: 'Edit',
        cancel: 'Cancel',
        save: 'Save',
        mandatory: '* Mandatory fields',
      },
      patientAddressPage: {
        heading: 'Address:',
        form: {
          address: {
            street: 'Street and number *',
            building: 'Building / apartment / other',
            postcode: 'Postcode *',
            city: 'City *',
            county: 'County',
            notMandatoryCounty: 'County',
            country: 'Country *',
          },
          mandatoryFields: '* Mandatory fields',
          additionalAddress: 'Add additional address',
          clearAll: 'Clear all',
          save: 'save',
          cancel: 'Cancel',
          update: 'Update',
          deleteadditionaladdress: 'Delete additional address',
          errorMessages: {
            mandatoryField: 'This field is mandatory.',
            street: `Please fill in the patient's street address.`,
            buildingNumberName: `Please fill in the patient's building number.`,
            postcode: `Please fill in the patient's postcode.`,
            city: `Please fill in the patient's city.`,
            county: `Please fill in the patient's county.`,
            country: `Please fill in the patient's country.`,
            invalidPattren: 'Invalid postcode',
            selectCountry: 'Please select a country',
          },
        },
      },
      recentOrders: {
        lastOrders: 'Last orders',
        latestOrders: 'Latest orders',
        seeMore: 'See more in order history',
        noOrders: `You don't have any orders.`,
        noOrderText: 'When you submit an order, you will be able to view the status of your order here. ',
        noOrdersSX: `You have not ordered any products yet. When you submit the order, you will be able to view the status of your order here.`,
      },
      myProfilePage: {
        emailAddress: 'E-mail address:',
        phoneNumber: 'Phone number:',
        password: 'Password',
        changepassword: 'Change password',
        edit: 'Edit',
        preferences: 'Preferences',
        toggleLabelOn: 'YES',
        toggleLabelOff: 'NO',
        receiveOrderConfirmation: 'I want to receive order confirmation and shipment notification e-mails',
        updatePreferencesSuccess: 'Your preferences were changed successfully',
        contactInformationUpdateSuccess: 'Your contact information has been updated successfully',
        updatePreferencesFail:
          'There were some issue with your preferences update. Please try again or contact customer service',
      },
      contactInformation: {
        title: 'Title',
        firstName: 'First name*',
        lastName: 'Last name*',
        email: 'E-mail address',
        phonenumber: 'Phone number',
        cancel: 'Cancel',
        save: 'Save',
        clear: 'Clear',
        errorMessages: {
          requireFirstName: 'Please fill in First name',
          moreThanThirtyCharacters: 'You cannot enter more than 30 characters in this field.',
          requireLastName: 'Please fill in Last name',
          phoneNumber: 'You cannot use letters in this field.',
          phoneNumberMinLength: 'Phone number is too short. Please verify if you entered all characters.',
        },
      },
      changePassword: {
        currentpassword: 'Current password*',
        newpassword: 'New password*',
        reenterpassword: 'Re-enter new password*',
        mandatory: '* Mandatory fields',
        showpassword: 'Show password',
        cancel: 'Cancel',
        save: 'Save',
        resetPassword: 'Send password reset e-mail',
        suggestion: 'Use 8 or more characters with a mix of letters, numbers & symbols',
        errorMessages: {
          requireCurrentpassword: 'Please enter current password',
          requireNewPassword: 'Please enter new password',
          requireReEnterpassword: 'Please re-enter new password',
          match: `Your passwords don't match. Try again.`,
          strong: 'Please choose a stronger password. Try a mix of letters, numbers & symbols.',
          maxCharcters: 'Use 8 characters or more for your password.',
        },
        resetPasswordConfirmation: 'Reset password e-mail sent',
        resetPasswordError: 'Something went wrong. Please try again or contact customer service',
      },
      patientOrderHistory: {
        io: 'Individual Orders ',
        subscription: 'Subscriptions ',
        subscriptions: 'Subscriptions',
        individualorders: 'Individual orders',
        regular: 'Regular',
        valuepack: 'Value pack',
        practiceorder: 'Practice order',
        directtopatient: 'Direct to patient',
        switchSub: 'Switch to subscription',
        nof: 'No orders found in specified time period',
        orderNo: 'Order no.',
        mainAddress: 'Main address',
        addAddress: 'Additional Address',
        orderPlaced: 'Order placed:',
        orderSent: 'Order sent:',
        nextOrder: 'Next order:',
        subCreated: 'Subscription created:',
        every: 'Every',
        active: 'ACTIVE',
        inactive: 'INACTIVE',
        noOrdersYet: 'No orders yet',
        oneTime: 'One time',
        reOrder: 'Reorder',
        frequency: 'Frequency',
        deleteSub: 'Are you sure you want to delete <br> this subscription ?',
        editSub: 'Do you want to activate <br> this subscription?',
        scheduleOrderYes: 'Yes',
        scheduleOrderNo: 'No',
        canDelOnlyActive: 'You can delete only inactive subscriptions',
        deactivateScheduleOrder: 'Are you sure you want to deactivate <br> this scheduled order?',
        popupYesText: 'Yes',
        popupNoText: 'NO',
        activeToInactive: 'Active subscription \n Click to deactivate',
        inactiveToActive: 'Inactive subscription \n Click to activate',
        delPatientConfirmation: 'Are you sure you want to delete this patient?',
        delPatient: 'Are you sure you want to delete ?',
        delPatientSuceess: 'Patient deleted successfully',
        deletedSuccessfully: 'Deleted successfully',
        delPatientFail: 'We are not able to delete this patient ',
        delFailMsg:
          'There are some items for this patient in your cart. \n Please remove those items and use Delete option again.',
        goToCart: 'GO TO CART',
        cartLink: 'cart',
        hasActiveOrders: 'Patient has active order in session cart.',
        infoMsg:
          'This patient still has active scheduled orders. If you <br> delete this patient now, sent orders will be delivered <br> and all scheduled orders will be cancelled. <br> This cant be undone.',
      },
      accountDetailsPage: {
        requestChangeAddress: 'Request change of address',
        shippingAddress: 'Shipping address',
        billingAddress: 'Billing address',
        orderconsolidationenabled: 'Order consolidation enabled',
        alconAccount: 'Alcon account',
        shippingAccount: 'Shipping account',
        switchText: 'Switch',
      },
      commonTitles: {
        edit: 'Edit',
        delete: 'Delete',
      },
      visioncareSearch: {
        searchFor: 'Search for:',
        searchProductPlaceholder: `Type product's name`,
        searchPatientPlaceholder: `Type patient's name`,
        patient: 'Patient',
        product: 'Product',
        invoicetoaccount: 'Invoice to account',
      },
      subscriptions: {
        nosubscriptions: `You don't have any subscriptions`,
        subscriptions: 'Subscriptions',
        allPatients: 'All Patients',
        subscriptionMsg: 'Subscriptions are scheduled orders that are delivered directly to patients or to a practice.',
        subscriptionSuccessMessage: 'Subscription is active. The first order will be sent on the selected day.',
        subscriptionInactiveMessage: 'Subscription is inactive. Changes saved.',
      },
      returnForm: {
        noProducts: 'There are no products to be returned',
        searchProducts: 'Search product or select from the list',
        selectProductHeader: 'Select the product you want to return:',
        addAnotherProduct: 'Add another product to return:',
        baseCurve: 'Base curve:*',
        power: 'Power:*',
        add: 'Add*:',
        colour: 'Colour*:',
        cylinder: 'Cylinder*:',
        axis: 'Axis*:',
        packSize: 'Pack size:',
        diameter: 'Diameter:',
        ordernumber: 'Order no:*',
        quantity: 'Quantity:*',
        mandatory: '* Mandatory fields',
        delete: 'Delete product',
        edit: 'Edit product',
        save: 'SAVE PRODUCT',
        returnReason: 'Return reason',
        dateoforder: 'Date of order:',
        reasonMandatory: 'Please identify the reason for returning the products *',
        selectreason: 'Select reason',
        quantityError:
          'You cannot choose more products of a given type than was ordered. If you are certain about the product quantity, verify the order number',
        orderNumberError:
          'This order number was already used in your return form. Change your order number or delete this item',
        returnproduct: 'RETURN PRODUCTS',
        completeFormError: 'Complete the form to return products',
        specifications: {
          baseCurve: 'Base curve:',
          power: 'Power:',
          add: 'Add:',
          colour: 'Colour:',
          cylinder: 'Cylinder:',
          axis: 'Axis:',
          packSize: 'Pack size:',
          diameter: 'Diameter:',
          ordernumber: 'Order no:',
          quantity: 'Qty:',
        },
        confirmation: {
          header: 'Your return is ready',
          returnInfoMessage: 'Your return form will be sent to your e-mail address:',
          remember: 'Remember',
          sendcopy: 'Send copy to alternative e-mail address',
          alternativeEmail: 'Provide alternative e-mail',
          send: 'SEND',
          emailError: 'E-mail is in the incorrect format. Please use the following format: john@gmail.com',
        },
      },
      statements: {
        statementForDownload: 'Statements for download',
        tooltipText: 'The first statement will be available at the beginning of the next month.',
        downloadText: 'Download statement (PDF)',
      },
      reOrderValidation: {
        DIRECT_TO_PATIENT_MESSAGE: `Items were added to the cart for patient's main address. Verify address in the cart`,
        GENERIC_API_ERRORMESSAGE: 'Items were not added to the cart. Please try again later or contact customer service',
        GENERIC_MESSAGE: 'Items were added to the cart',
        heading: 'There is an issue with your reorder',
        itemDiscontinued: 'Please contact customer service.',
        maxLimit: 'Please submit your current cart before reordering products or reconfigure your order.',
        ok: 'OK',
        patientDeleted: 'Patient was deleted and is no longer available for ordering.',
        valuePackQauntity: '1 item',
        vpSKUDiscontinued: 'Please re-configure your order or contact customer service.',
      },
      autoOrderingTranslations: {
        heading: 'Automatic shopping cart enabled',
        timeSet: 'Time set',
        setTime: 'Set time',
        save: 'Save',
        cancel: 'Cancel',
        ENABLE_SUCCESS_MSG: 'Automatic shopping cart ordering is enabled at specified time',
        DISABLE_SUCCESS_MSG: 'Automatic shopping cart ordering is disabled.',
        ERROR_MSG: 'Something went wrong. Please try again. If issue is repeating, please contact customer service',
        timeErrorMsg: 'Please provide correct time',
      },
      faqSearch: {
        searchLabel: 'Search for an answer',
        searchSuggestion: 'You can also browse the topics below to find what you are searching for. ',
      },
      sxRegistration: {
        email: 'E-mail address',
        cancel: 'Cancel',
        next: 'NEXT',
        emailVal: 'Please enter a valid e-mail',
        title: 'Registration to MyAlcon',
        passwordVal: 'Please enter your password',
        mandatory: '* Mandatory fields',
        login: 'LOG IN',
        invalidEmail: 'Invalid email address',
        emailExist: 'A user with this {0} already exists',
        phonelong: 'Phone number is too long',
        phoneshort: 'Phone number is too short',
        select: 'Select',
        consentLinks: {
          termsCondition: 'https://www.alcon.com/terms-use?_ga=2.124201935.434608583.1555915818-1936277745.1549032292',
          privacyPolicy:
            'https://www.alcon.com/privacy-policy?_ga=2.124201935.434608583.1555915818-1936277745.1549032292',
          marketingPrivacyPolicy:
            'https://www.alcon.com/privacy-policy?_ga=2.124201935.434608583.1555915818-1936277745.1549032292',
        },
      },
      loginPage: {
        email: 'E-mail address',
        cancel: 'Back to login',
        emailVal: 'Please enter a valid e-mail',
        passwordVal: 'Please enter your password',
        login: 'LOG IN',
        sxTitle: 'Surgical India',
        vcTitle: 'Visioncare India',
        selectZone: 'Select zone',
        selectBranch: 'Select branch',
      },
      toggleValues: {
        active: 'ACTIVE',
        inactive: 'INACTIVE',
        yes: 'YES',
        no: 'NO',
        enabled: 'ENABLED',
      },
      userManagementPage: {
        noExistingUsersMessage: 'You have no existing users yet.',
        fetchingUsers: 'Fetching Users ...',
        edit: 'Edit',
        userManagementTab: {
          pendingRequest: 'Pending requests',
          existingUsers: 'Existing users',
        },
      },
      ManageuserPage: {
        PermissionsHeader: 'Permissions',
        AlconAccount: 'Alcon Account',
        Address: 'Address',
        Access: 'Access',
        save: 'Save changes',
        rejectRequest: 'REJECT REQUEST',
        Cancel: 'Cancel',
        EditPermissions: 'Edit permissions',
        enabled: 'ENABLED',
        noAccessYet: 'No access yet',
        inactive: 'INACTIVE',
        ShippingID: 'Shipping ID',
        Selectall: 'Select all',
        UserLabel: 'User',
        AdministratorLabel: 'Administrator',
        noLongerAccessible: 'Error occurred. User account is no longer accessible',
        confirmationMessages: {
          canclePermissionConfirmation: 'Are you sure you want to cancel?',
          canclePermissionInfo: 'Changes will not be saved.',
          canclePermissionYes: 'Yes',
          canclePermissionNo: 'No',
        },
        editPermissionErrorMsg: 'Something went wrong. Please try again or contact customer service',
        gotoUserList: 'Go to user list',
        userRoleToolTipTxt: 'This type of user has access level as given by the Administrator',
        adminRoleToolTipTxt:
          'This type of user will be able to approve/reject new users and set their level of access. Administrator has access to all features.',
        permissionClose: 'Close',
      },
    },
  
    asm: {
      asm: {
        mainLogoLabel: 'ASM',
        mainTitle: 'Assisted Service Mode',
        logout: 'Sign Out',
        hideUi: 'Close ASM',
        toggleUi: {
          collapse: 'Hide ASM',
          expand: 'Show ASM',
        },
        loginForm: {
          submit: 'Sign In',
          userId: {
            label: 'Agent ID',
            required: 'Agent ID is required',
          },
          password: {
            label: 'Password',
            required: 'Password is required',
          },
        },
        customerSearch: {
          searchTerm: {
            label: 'Customer Name/Email Address',
          },
          submit: 'Start Session',
          noMatch: 'No customer found.',
        },
        endSession: 'End Session',
        agentSessionTimer: {
          label: 'Session Timeout',
          minutes: 'min',
          reset: 'Reset',
        },
      },
    },
    autoOrderingTranslations: {
      heading: 'Automatic shopping cart enabled',
      timeSet: 'Time set',
      setTime: 'Set time',
      save: 'Save',
      cancel: 'Cancel',
      ENABLE_SUCCESS_MSG: 'Automatic shopping cart ordering is enabled at specified time',
      DISABLE_SUCCESS_MSG: 'Automatic shopping cart ordering is disabled.',
      ERROR_MSG: 'Something went wrong. Please try again. If issue is repeating, please contact customer service',
    },
  };
  