import { OrderTotalPrice } from 'src/app/shared/model/orderHistory.model';
import { AddressInfo } from 'src/app/shared/model/patient.model';

export interface AppState {
  visionCareCart: VisionCareCartItems;
}
export interface OrderDetailsObj extends VisionCareCartItems {
  purchaseOrderNumber?: string;
  status?: string;
}
export interface VisionCareCartItems {
  code: string;
  rootGroups: VisionCareEntries[];
  deliveryAddress: DeliveryAddressInfo;
  calculated: boolean;
  totalUnitCount: number;
  title?: string;
  total?: string;
  cartError?: any;
  LastmodifiedType?: string;
  deliveryMode?: any;
  positionId?: string;
  deliveryCost?: any;
  totalTax?: any;
  totalPrice?: any;
  subTotal?: any;
  paymentType?: {
    code: string;
    displayName: string;
  };
  errorMessage?: string;
  orderSimulation?: boolean;
}

export interface DeliveryAddressInfo {
  isPONumberRequired: boolean;
}

export interface VisionCareEntries {
  orderType?: string;
  label?: string;
  children?: any;
  totalPrice?: OrderTotalPrice;
  entries?: VisionCareGroupEntries[];
  count?: number;
}

export interface VisionCareGroupEntries {
  groupId?: string;
  count?: string;
  quantity?: number;
  entries?: VisionCareProductEntries[];
  patientId?: string;
  label?: string;
  shippedEntries?: number;
  unshippedEntries?: number;
  entryNumber?: number;
  shippingAddress?: AddressInfo;
}

export interface VisionCareProductEntries {
  productCode?: string;
  productName?: string;
  image?: string;
  formattedPrice?: string;
  qty?: number;
  specification?: VisionCareCartSpecification[];
  basePrice?: OrderTotalPrice;
  creationtime?: string;
  orderEntryFlowType?: string;
  shipped?: number;
  unShipped?: number;
  quantity?: number;
  entries?: VisionCareProductEntries[];
}

export interface VisionCareCartSpecification {
  specificationKey?: string;
  specificationValue?: string;
}

export interface VisionCareAddToCart {
  entries?: VisionCareAddtocartProductRequest[];
  orderFlowType?: string;
  trialProduct?: boolean;
  patient?: VisionCarePatient;
  entryNumber?: number;
  valuePackId?: string;
  recurrencePeriod?: string;
  numberOfWeeks?: string;
  nthDayOfMonth?: string;
  scheduledStartDate?: string;
  positionId?: string;
}

export enum RecurrencePeriod {
  weekly = 'WEEKLY',
  monthly = 'MONTHLY',
}

export interface VisionCareAddtocartProductRequest {
  product?: VisionCareAddtocartProductRequestInfo;
  eyeSight?: string;
  quantity?: string;
  orderEntryFlowType?: string;
  patientName?: string;
  entryNumber?: number;
  disabled?: boolean;
  patientId?: string;
  patientShippingAddressMain?: boolean;
  valuePackAttributes?: ValuePackAttributes;
  shippingAddress?: AddressInfo;
}
export interface ValuePackAttributes {
  valuePackId?: number;
  valuePackDuration?: number;
  valuePackType?: boolean;
}

export interface VisionCareAddtocartProductRequestInfo {
  code?: number | string;
}

export interface VisionCarePatient {
  patientId?: string;
}
export interface RemoveFromCartData {
  code: string;
  entries: Array<string>;
}

export interface ScheduledOrderData {
  repeatOrderOption: string;
  repeatOrderValue: string;
  defaultDate: Date;
}

export enum EntryType {
  PRACTICE_ORDER = 'PRACTICE_ORDER',
  DIRECT_TO_PATIENT = 'DIRECT_TO_PATIENT',
  INDIVIDUAL_ORDER = 'INDIVIDUAL_ORDER',
  STOCK_ORDER = 'STOCK_ORDER',
}

export enum OrderEntryFlowType {
  DTP_VALUE_PACK = 'DTP_VALUE_PACK',
  DIRECT_TO_PATIENT = 'DIRECT_TO_PATIENT',
  REGULAR = 'REGULAR',
}

export const ContactLensSolutionType = {
  DryEyeProducts: 'dryEyeProducts',
  ContactLensSolution: 'contactLensSolution',
};

export enum OrderType {
  Individual = 'Individual',
  Scheduled = 'Scheduled',
}

export const DeliveryTypesMapping = {
  PRACTICE_ORDER: 'REGULAR',
  DIRECT_TO_PATIENT: 'DIRECT_TO_PATIENT',
};

export interface OrderTypeSummary {
  orderCount?: number;
  patientCount?: number;
  totalPriceinfo?: {};
  orders?: any[];
}

export interface DtpPraticeExist {
  practiceExist?: boolean;
}
