export const environment = {
  production: true,
  LANGUAGE: 'en_GB',
  API_BASE_URL: 'https://api.cb7ya-novartisp2-s1-public.model-t.cc.commerce.ondemand.com',
  CURRENCY: 'GBP',
  API_PREFIX: '/occ/v2/',
  BASE_SITE: 'alconUK',
  ISSUER: 'https://qa-alcon-axon.oktapreview.com/oauth2/aus2uoy2wkCVHEPmR1d7',
  BASE_URL: 'https://qa-alcon-axon.oktapreview.com',
  CLIENT_ID: '0oa3dilbrz5DaIEJZ1d7',
  REDIRECT_URL: 'https://shop-s1.myalconstore.net',
  OKTA_BASE_URL: 'https://qa.axon.alconcloud.com/v6/',
  TEALIUM_ACCOUNT: 'alcon',
  TEALIUM_PROFILE: 'ecommerce-intl',
  TEALIUM_ENV: 'qa',
  OKTA_WIDGET_ENV: 'qa',
};
